.title {
  width: 100%;
  display: flex;
  margin-left: 0.25rem;
  margin-bottom: 1.0625rem;
  justify-content: space-between;
  align-items: center;
}
.root {
  width: 100%;
  margin-bottom: 63px;
}

.text_wrapper {
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  max-width: 500px;
  padding-right: 40px;
  justify-content: center;
  height: 100%;
  padding-bottom: 30px;
  @media (min-width: 576px) {
    padding-bottom: 0;
  }
  h4 {
    font-size: 24px;
    line-height: 1.5;
    margin-bottom: 16px;
    letter-spacing: 0.6px;
    color: #000;
    max-width: 418px;
    @media (max-width: 576px) {
        font-size: 20px;
        line-height: 1.4;
      }
  }
  p {
    font-size: 16px;
    line-height: 1.5;
    margin-bottom: 8px;
    opacity: 0.8;
    max-width: 23.6875rem;
  }
}
