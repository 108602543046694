.banner{
    aspect-ratio: 873/369;
    border-radius: 0.625rem;
    overflow: hidden;
}
.main_product{
    height: 100%;
    position: relative;
    border-radius: 0.625rem;
    overflow: hidden;
}

.content_wrapper{
    width: 100%;
    display: flex;
    flex-direction: column;
    position: relative;
    padding-top: 12px;
    .title{
        width: 200px;
        height: 40px;
        margin-bottom: 14px;
    }
}

.main_product_sub{
    width: 100%;
    aspect-ratio: 277/369;
    border-radius: 0.625rem;
    overflow: hidden;
}