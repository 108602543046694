.slider_wrapper{
    width: 100%;
    display: flex;
    flex-direction: column;
    position: relative;

    .main_slider{
        width: 100%;
        position: relative;

    border-radius: 10px;
    overflow: hidden;

        .image_wrapper{
            width: 100%;
            position: relative;
            // &::after{
            //     width: 100%;
            //     position: relative;
            //     padding-bottom: 100%;
            //     display: block;
            //     content: '';
            //     background-color: #fafafa;
            // }
            // img{
            //     z-index: 1;
            //     position: absolute;
            //     left: 0;
            //     top: 0;
            //     width: 100%;
            //     height: 100%;
            //     object-fit: cover;
            //     object-position: center;
            // }
        }


        :global{
            .swiper-button-prev,
            .swiper-button-next{
                width: 50px;
                height: 50px;
                display: flex;
                justify-content: center;
                align-items: center;
                background-color: #fff;
                border-radius: 50%;
                overflow: hidden;
                font-size: 0;
                position: relative;
                position: absolute;
                right: 20px;
                top: 50%;
                transform: translateY(-50%);
                z-index: 9;
                cursor: pointer;
                opacity: .6;
                transition: .3s all ease;
                &:hover{
                    opacity: 1;
                }
                &.swiper-button-disabled{
                    opacity: .3;
                    pointer-events: none;
                }
                &::after{
                    content: '';
                    position: absolute;
                    left: 50%;
                    top: 50%;
                    transform: translate(-69%, -50%) rotate(-45deg);
                    width: 12px;
                    height: 12px;
                    border-right: 2px solid #000;
                    border-bottom: 2px solid #000;
                }
            }
            .swiper-button-prev{
                left: 20px;
                right: auto;
                transform: translateY(-50%) rotate(-180deg);
            }
        }
    }

    .thumb_slider{
        width: 100%;
        position: relative;
        margin-top: 10px;

        .image_wrapper{
            width: 100%;
            position: relative;
            border-radius: 5px;
            overflow: hidden;
            &::after{
                width: 100%;
                position: relative;
                padding-bottom: 100%;
                display: block;
                content: '';
                background-color: #fafafa;
            }
            &::before{
                width: 10px;
                height: 10px;
                position: absolute;
                content: '';
                right: 10px;
                top: 10px;
                border-radius: 50%;
                overflow: hidden;
                background-color: #fff;
                z-index: 3;
                border: 1px solid #592E18;
                opacity: 0;
                pointer-events: none;

            }
            img{
                z-index: 1;
                position: absolute;
                left: 0;
                top: 0;
                width: 100%;
                height: 100%;
                object-fit: cover;
                object-position: center;
            }
            
        }

        :global(.swiper-slide-thumb-active) .image_wrapper{
            &::before{
                opacity: 1;
            }
        }
        :global{
            .swiper-button-prev,
            .swiper-button-next{
                width: 50px;
                height: 50px;
                display: flex;
                justify-content: center;
                align-items: center;
                z-index: 2;
                border-radius: 50%;
                overflow: hidden;
                font-size: 0;
                position: relative;
                position: absolute;
                right: 20px;
                top: 50%;
                transform: translateY(-50%);
                z-index: 9;
                cursor: pointer;
                opacity: .6;
                transition: .3s all ease;
                &:hover{
                    opacity: 1;
                }
                &.swiper-button-disabled{
                    opacity: .3;
                    pointer-events: none;
                }
                &.swiper-button-lock{
                    display: none;
                }
                &::after{
                    content: '';
                    position: absolute;
                    left: 50%;
                    top: 50%;
                    transform: translate(-69%, -50%) rotate(-45deg);
                    width: 12px;
                    height: 12px;
                    border-right: 2px solid #000;
                    border-bottom: 2px solid #000;
                }
            }
            .swiper-button-prev{
                left: 20px;
                right: auto;
                transform: translateY(-50%) rotate(-180deg);
            }
        }
    }

    
}