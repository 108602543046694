.root{
    border-radius: .625rem;
    overflow: hidden;
    position: relative;
    :global(.swiper-button-prev),
    :global(.swiper-button-next){
        &:global(.swiper-button-disabled){
            opacity: .4;
        }
        position: absolute;
        bottom: .9375rem;
        left: 100px;
        width: 1.3125rem;
        height: 1.3125rem;
        background-repeat: no-repeat;
        background-size: contain;
        font-size: 0;
        z-index: 99;
        cursor: pointer;
        transition: .15s all ease;
        background-image: url("data:image/svg+xml,%3Csvg width='21' height='18' viewBox='0 0 21 18' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M0.664561 10.0776L15.4728 10.0776L8.67098 15.9503L10.3988 17.4316L20.1331 9.02701L10.3988 0.622443L8.68315 2.10375L15.4728 7.97644L0.664561 7.97644L0.664561 10.0776Z' fill='white'/%3E%3C/svg%3E%0A");
    }
    :global(.swiper-button-prev){
        left: 50px;
        transform: scaleX(-1);
    }
}
.image{
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    img{
        position: absolute;
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center;
    }
}

.banner_wrapper{
    background-color: #000;
    aspect-ratio: 873 / 369;
    padding: 54px;
    color: #fff;
    border-radius: .625rem;
    overflow: hidden;
    padding-bottom: 3.75rem;
    @media (max-width : 576px) {
        aspect-ratio: 873 / 500;
        padding: 24px;
        padding-bottom: 56px;
    }
    &::after{
        background: rgb(0,0,0);
        background: -moz-linear-gradient(180deg, rgba(0,0,0,0) 0%, rgba(0,0,0,1) 100%);
        background: -webkit-linear-gradient(180deg, rgba(0,0,0,0) 0%, rgba(0,0,0,1) 100%);
        background: linear-gradient(180deg, rgba(0,0,0,0) 0%, rgba(0,0,0,1) 100%);
        filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#000000",endColorstr="#000000",GradientType=1);
        position: absolute;
        height: 40%;
        width: 100%;
        left: 0;
        bottom: 0;
        content: '';
        opacity: .5;
    }
    &::before{
        background: rgb(0,0,0);
        background: -moz-linear-gradient(180deg, rgba(0,0,0,0) 0%, rgba(0,0,0,1) 100%);
        background: -webkit-linear-gradient(180deg, rgba(0,0,0,0) 0%, rgba(0,0,0,1) 100%);
        background: linear-gradient(180deg, rgba(0,0,0,0) 0%, rgba(0,0,0,1) 100%);
        filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#000000",endColorstr="#000000",GradientType=1);
        position: absolute;
        height: 60%;
        width: 100%;
        left: 0;
        top: 0;
        content: '';
        transform: scaleY(-1);
        opacity: .08;
    }
    .text_wrapper{
        width: 100%;
        display: flex;
        flex-direction: column;
        position: relative;
        height: 100%;
        justify-content: flex-end;
        z-index: 9;
        .title{
            width: 100%;
            position: relative;
            margin-bottom: 8px;
            h2{
                font-size: 30px;
                @media (max-width : 576px) {
                    font-size: 24px;
                }
            }
        }
    }
   
}
.hideShadow.banner_wrapper::before,
.hideShadow.banner_wrapper::after{
    display: none;
}
.link{
    position: absolute;
    top: .9375rem;
    right: .9375rem;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    z-index: 9;
    background-color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: .3s all ease;
    &:hover{
        filter: invert(100%);
    }
}

.button{
    a{
        display: flex;
        flex-direction: row;
        span{
            display: inline-block;
            width: max-content;
            
            
        }
    }
}