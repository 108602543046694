.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


.shimmer-container {
  width: 100%;
  background-color: #eee;
  border-radius: 5px;
}

.shimmer-element {
  width: 100%;
  height: 100%;
  background: linear-gradient(to right, #f6f7f8 0%, #f0f1f2 50%, #f6f7f8 100%);
  animation: shimmer 2s infinite ease-in-out;
}

@keyframes shimmer {
  from {
      background-position: -200px 0;
  }
  to {
      background-position: 200px 0;
  }
}