.section {
    width: 100%;
    position: relative;
    
    :global(.container){
        position: relative;
        padding-top: 50px;
    }

    
}

.heading_wrapper {
    width: 100%;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .title {
        width: 100%;
        position: relative;
        font-size: 30px;
        max-width: 474px;
        margin-left: auto;
        margin-right: auto;
        text-align: center;
        line-height: 1.4;

        @media (min-width: 991px) {
            font-size: 25px;
        }
    }
}


.list_wrapper{
    width: 100%;
    position: relative;
    display: flex;
    flex-direction: column;
    max-width: 750px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 50px;
    ul{
        width: 100%;
        position: relative;
        padding: 0;
        margin: 0;
        li{
            width: 50%;
            position: relative;
            padding: 0px 15px 15px;
            position: relative;
            list-style: none;
            @media (min-width: 991px) {
                padding: 0px 30px 30px;
            }
            h5{
                font-size: 16px;
                margin-bottom: 5px;
                transform: translateY(-8px);
                @media (min-width: 576px) {
                    font-size: 20px;
                }
            }
            p{
                line-height: 1.6;
                opacity: .7 ;
                transform: translateY(-8px);
                font-size: 14px;
                @media (min-width: 576px) {
                    font-size: 16px;
                }
            }
            &::after{
                width: 6px;
                height: 6px;
                background-color: #592E18;
                border-radius: 50%;
                overflow: hidden;
                
                content: '';
                position: absolute;
                left: calc(5px/-2);
                top: 5px;
            }
            &::before{
                position: absolute;
                content: '';
                width: 1px;
                height: 100%;
                left: 0px;
                top: 5px;
                border-right: 1px dashed rgba(71, 36, 19, 0.159);
            }
            &:nth-child(odd){
                margin-left: auto;
            }
            &:nth-child(even){
                text-align: right;
                &::after{
                    left: auto;
                    right: calc(7px/-2);
                }
                &::before{
                    left: auto;
                    right: -1px;
                }
            }
            &:last-child{
                &::before{
                    display: none;
                }
            }
        }
    }
}


.shop_now_wrapper{
    width: 100%;
        position: relative;
        background-color: #592e19;
        color: #fff;
        padding: 30px 50px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin-top: 50px;
        &::after{
            width: 100%;
            top: -6px;
            height: 7px;
            content: "";
            position: absolute;
            content: "";
            left: 0;
            background-size: 31px;
            background-image: url("data:image/svg+xml,%3Csvg width='87' height='20' viewBox='0 0 87 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M64.7 8.9C58.7 3 50.9 0 43.1 0C35.3 0 27.5 3 21.6 8.9C15.6 14.9 7.8 17.8 0 17.8V19.8H86.2V17.8C78.4 17.9 70.6 14.9 64.7 8.9Z' fill='%23592E18'/%3E%3C/svg%3E%0A");
        }
        h4{
            width: 100%;
            max-width: 500px;
            text-align: center;
            position: relative;
            color: #fff;
            line-height: 1.5;
            margin-bottom: 10px;
        }
        .link{
            position: relative;
            text-decoration: none;
            color: inherit;
            span{
                display: inline-block;
                margin-left: 10px;
            }
        }
}



.waves{
    width: 100%;
}