.header{
    width: 300px;
    position: fixed;
    left: 0;
    top: 0;
    bottom: 0;
    height: 100%;
    &.open{
        width: 80px;
        & + main{
            padding-left: 100px;
        }
    }
    .header_inner{
        width: 100%;
        position: relative;
        height: 100%;
        overflow: hidden;
        overflow-y: auto;
        background-color: #fcf9f9;
        border-right: 1px solid #efebeb;

        .logo{
            width: 100%;
            height: 60px;
            overflow: hidden;
            background-color: #efebeb;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 14px;
            line-height: 1;
            font-weight: 500;
            padding-right: 20px;
        }

        .menu{
            width: 100%;
            position: relative;
            padding: 20px;
            .menu_inner{
                width: 100%;
            }


            .menu_items{
                width: 100%;
                display: flex;
                flex-direction: column;
                position: relative;
                padding-left: 40px;
                .menu_item{
                    position: relative;
                    .link{
                        display: block;
                        position: relative;
                        text-decoration: none;
                        color: #000;
                        padding: 4px 0px;
                        margin: 4px 0px;
                        font-size: 15px;
                        transition: .15s all ease;
                        &:hover{
                            color: #000;
                        }
                        &::after{
                            width: 1px;
                            height: calc(100% + 6px);
                            content: "";
                            position: absolute;
                            left: -26px;
                            top: 16px;
                            border-right: 1px dashed #e5e5e5;
                        }
                        &::before{
                            content: "";
                            display: inline-block;
                            position: absolute;
                            width: 4px;
                            height: 4px;
                            border-radius: 50%;
                            overflow: hidden;
                            left: -27px;
                            top: 14px;
                            background-color: #858585;
                            z-index: 7;
                        }
                    }
                    &:last-child{
                        .link::after{
                            display: none;
                        }
                    }
                }
            }
        }
    }
}

.header_icon{
    position: absolute;
    right: -15px;
    top: 13px;
    width: 30px;
    height: 30px;
    overflow: hidden;
    border: 1px solid #efebeb;
    border-radius: 50%;
    background-color: #fff;
    &::after{
        content: "";
        position: absolute;
        left: 10px;
        top: 11px;
        width: 6px;
        height: 6px;
        border-right: 1px solid #837f7f;
        border-bottom: 1px solid #837f7f;
        transform: rotate(-45deg);
    }
    cursor: pointer;
}


.link_logout{
    position: absolute;
    width: 100%;
    bottom: 0px;
    left: 0px;
    background-color: #000000;
    color: #ffffff;
    height: 50px;
    border: 0px;
    font-size: 18px;
    line-height: 1;
    font-weight: 500;
    text-transform: uppercase;
    transition: .3s background-color ease;
    &:hover{
        background-color: #848484;
    }
}

.menu_sub_item_parent{
    width: 100%;
    position: relative;
    font-size: 14px;
    padding: 5px 15px;
    background-color: #efebeb;
    border-radius: 3px;
    font-weight: 500;
    color: #717171;
    margin-bottom: 10px;
}