.root{
    width: 100%;
    display: flex;
    flex-direction: column;
    position: relative;
    background-color: #F8F6F5;
    padding: 40px 0px 48px;
    height: 100%;
    @media (max-width: 576px) {
        padding: 32px 0px 40px;
    }
    &::after{
        width: 100%;
        top: -7px;
        height: 7px;
        content: "";
        position: absolute;
        content: "";
        left: 0;
        background-size: 32px;
        background-image: url("data:image/svg+xml,%3Csvg width='87' height='20' viewBox='0 0 87 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg clip-path='url(%23clip0_527_11)'%3E%3Cpath d='M64.7 8.9C58.7 3 50.9 0 43.1 0C35.3 0 27.5 3 21.6 8.9C15.6 14.9 7.8 17.8 0 17.8V19.8H86.2V17.8C78.4 17.9 70.6 14.9 64.7 8.9Z' fill='%23F8F6F5'/%3E%3C/g%3E%3Cdefs%3E%3CclipPath id='clip0_527_11'%3E%3Crect width='86.2' height='19.8' fill='white'/%3E%3C/clipPath%3E%3C/defs%3E%3C/svg%3E%0A");
    }
}
.title{
    width: 100%;
    display: flex;
    margin-left: .25rem;
    margin-bottom: 24px;
    justify-content: center;
    align-items: center;
}

.comment_wrapper{
    width: 100%;
    position: relative;
    background-color: #fff;
    padding: 25px;
    overflow: hidden;
    border-radius: 5px;
    height: 100%;
    display: flex;
    flex-direction: column;
    border: 1px solid #e2e2e2;
    p{
        margin-bottom: 16px;
        line-height: 1.5;
        font-weight: 500;
    }
    h6{
        font-size: 14px;
        opacity: .8;
        margin-top: 8px;
        font-weight: 40;
    }
    h4{
        margin-top: auto;
    }
}