.mobile{
    position: fixed;
    left: 0;
    top: 0;
    height: 199vh;
    background-color: #fff;
    max-width: 250px;
    z-index: 999;
    width: 90%;
    padding: 20px;
    pointer-events: none;
    transform: translate3d(-100%, 0px, 0px);
    transition: .3s all ease-in-out;
    will-change: transform;
    &.active{
        pointer-events: all;
        transform: translate3d(0%, 0px, 0px);
    }

    ul{
        list-style: none;
        padding: 0;
        margin: 0;
        display: flex;
        flex-direction: column;
        width: 100%;
        li{
            margin-bottom: 15px;
            width: 100%;
            a{
                width: 100%;
                display: block;
                text-decoration: none;
                color: #000;
                font-weight: 400;
                font-size: 16px;
                position: relative;

                &::after{
                    content: '';
                    position: absolute;
                    right: 0;
                    top: 0;
                    bottom: 0;
                    width: 7px;
                    height: 7px;
                    border-right: 1px solid #000;
                    border-bottom: 1px solid #000;
                    margin: auto;
                    transform: rotate(-45deg);
                    opacity: .5;
                    pointer-events: none;
                }

            }
        }
    }
}

.overlay{
    width: 100%;
    position: fixed;
    height: 100%;
    background-color: #000;
    left: 0;
    top: 0;
    z-index: 99;
    opacity: 0;
    pointer-events: none;
    &.active{
        opacity: .3;
        pointer-events: all;
    }
}