.root{
    width: 100%;
    position: relative;
    height: 100%;
    border-radius: 0.625rem;
    overflow: hidden;
    color: #fff;
    background-color: #000;
    .wrapper{
        width: 100%;
        height: 100%;
    }
    .image{
        height: 100%;
        position: relative;
        img{
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: center;
        }
        &::after{
            background: rgb(0,0,0);
            background: -moz-linear-gradient(180deg, rgba(0,0,0,0) 0%, rgba(0,0,0,1) 100%);
            background: -webkit-linear-gradient(180deg, rgba(0,0,0,0) 0%, rgba(0,0,0,1) 100%);
            background: linear-gradient(180deg, rgba(0,0,0,0) 0%, rgba(0,0,0,1) 100%);
            filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#000000",endColorstr="#000000",GradientType=1);
            position: absolute;
            height: 40%;
            width: 100%;
            left: 0;
            bottom: 0;
            content: '';
            opacity: .5;
        }
        &::before{
            background: rgb(0,0,0);
            background: -moz-linear-gradient(180deg, rgba(0,0,0,0) 0%, rgba(0,0,0,1) 100%);
            background: -webkit-linear-gradient(180deg, rgba(0,0,0,0) 0%, rgba(0,0,0,1) 100%);
            background: linear-gradient(180deg, rgba(0,0,0,0) 0%, rgba(0,0,0,1) 100%);
            filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#000000",endColorstr="#000000",GradientType=1);
            position: absolute;
            height: 60%;
            width: 100%;
            left: 0;
            top: 0;
            content: '';
            transform: scaleY(-1);
            opacity: .08;
        }
    }
    .text_wrapper{
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        z-index: 9;
        padding: 15px;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
    }
    .detail_wrapper{
        width: 100%;
        display: flex;
        justify-content: space-between;
        .price{
            font-weight: 600;
        }
    }
    .link_wrapper{
        display: flex;
        gap: .5625rem;
        margin-top: 8px;
        a{
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            text-align: center;
            font-size: 18px;
            height: 40px;
            background-color: #fff;
            color: #000;
        }
    }
    .featured_product{
        position: absolute;
        top: 15px;
        left: 15px;
        background-color: var(--bs-primary);
        color: #fff;
        z-index: 9;
        padding: 3px 18px;
        letter-spacing: -0.9px;
        border-radius: 5px;
    }
}