.root{
    --gutter  : 20px;
    width: 100%;
    position: relative;
    display: flex;
    overflow: hidden;
    overflow-y: auto;
   
    .list{
        width: auto;
        display: flex;
        padding: 0;
        margin: 0;
        margin: 0px calc(var(--gutter) * -1);
        li{
            width: auto;
            list-style: none;
            margin: 0px var(--gutter);
            padding: 4px;
        }
    }
}


.link{
    width: 100%;
    position: relative;
    display: flex;
    text-decoration: none;
    align-items: center;
    .image_wrapper{
        width: 28px;
        height: 28px;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        &::after{
            width: 30px;
            height: 30px;
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
            content: '';
            border-radius: 50%;
            border: 1px solid #D30000;
            opacity: 0;
        }
       
        img{
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: center;
            border-radius: 50%;
            overflow: hidden;
        }
    }
    .text_wrapper{
        font-size: 1rem;
        color: #000;
        margin-bottom: 0;
        margin-left: 10px;
    }
}


.active{
    img{
        transform: scale(.8);
    }
    .image_wrapper::after{
        opacity: 1;
    }
}