.root {
  width: 100%;
  position: relative;
  color: #fff;
  margin-bottom: 40px;
  @media (max-width: 991px) {
    margin-bottom: 8px;
  }
  .inner_wrapper {
    width: 100%;
    position: relative;
    border-radius: 0.625rem;
    overflow: hidden;
    background: rgb(89,46,24);
    background: -moz-linear-gradient(0deg, rgb(60, 29, 14) 0%, rgba(103,53,27,1) 51%, rgb(60, 29, 14) 100%);
    background: -webkit-linear-gradient(0deg, rgb(60, 29, 14) 0%, rgba(103,53,27,1) 51%, rgb(60, 29, 14) 100%);
    background: linear-gradient(0deg, rgb(60, 29, 14) 0%, rgba(103,53,27,1) 51%, rgb(60, 29, 14) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#592e18",endColorstr="#592e18",GradientType=1);
    padding: 32px;
    @media (max-width: 576px) {
        padding: 24px 16px;
      }
    @media (min-width: 991px) {
      padding: 3.65rem 3.375rem;
    }
  }
  .image_wrapper {
    opacity: 0.25;
  }
  .image_wrapper,
  img {
    width: 100%;
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    object-fit: cover;
    object-position: center;
  }
  .text_wrapper {
    position: relative;
    z-index: 99;
    a {
      width: max-content;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      text-align: center;
      display: flex;
      font-size: 16px;
      color: #fff;
      font-weight: 500;
      span {
        display: flex;
        width: max-content;
        &:nth-child(2) {
          color: #fff;
        }
      }
    }

    .title {
      font-size: 28px;
      line-height: 1.3;
      margin-bottom: 16px;
      letter-spacing: 0.6px;
      max-width: 551px;
      @media (max-width: 576px) {
        font-size: 20px;
        line-height: 1.4;
      }
      &:global(.ttl-4) {
        margin-bottom: 8px;
        // margin-left: -0.4375rem;
        line-height: 1.6;
      }
    }
  }
  .connect_wrapper {
    width: 100%;
    margin: auto;
    margin-top: -0.3125rem;
    margin-top: 30px;
    @media (min-width: 991px) {
      max-width: 12.875rem;
      margin-top: 0;
    }
  }
  .connect_link {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    position: relative;
    column-gap: 32px;
    margin-left: -0.3125rem;
    .icon {
      width: 2.875rem;
      height: 2.875rem;
      border-radius: 50%;
      z-index: 9;
      background-color: rgba(255, 255, 255, 0.195);
      display: flex;
      align-items: center;
      justify-content: center;
      transition: 0.3s all ease;
      &:hover {
        background-color: rgba(255, 255, 255, 0.3);
      }
      &:last-child {
        width: 100%;
        border-radius: 40px;
        margin-top: 27px;
        font-size: 1.125rem;
        line-height: 1;
        display: flex;
        align-items: center;
        justify-content: center;
        text-decoration: none;
        color: #ffffff;
        font-weight: 500;
      }
      span + span {
        margin-left: 8px;
      }
    }
  }
}
