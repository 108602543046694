.root{
    display: flex;
    flex-wrap: wrap;
    padding: 14px 0px;
    position: fixed;
    left: 0;
    top: 0;
    background-color: #fff;
    z-index: 999;
    width: 100%;
    transition: .3s all ease;
    :global(.container){
        display: flex;
        flex-wrap: wrap;
        align-items: center;
    }
    & ~ main{
        margin-top: 93px;
    }
    &.active{
        box-shadow: 1px 1px 10px rgba(0, 0, 0, 0.08);
    }
    .menu{
        display: none;
        margin-left: auto;
        @media (min-width: 991px) {
            display: flex;
        }
        :global(.nav-link){
            color: #000;
            text-decoration: none;
            font-weight: 400;
            font-size: 1rem;
            padding: .6563rem .9375rem;
        }
    }
    .contact{
        width: auto;
        display: flex;
        flex-wrap: wrap;
        background-color: #f3f3f3;
        padding: 10px 10px;
        margin-left: 1.0625rem;
        border-radius: .3125rem;
        @media (min-width: 991px) {
            padding: 0px 22px;
        }
        :global(.nav-link){
            padding-top: 0;
            padding-bottom: 0;
            position: relative;
            height: 100%;
            display: flex;
            align-items: center;
            padding: 0px;
        }
        :global(.nav-item:last-child){
            a{
                padding-left: 1.4063rem;
                margin-left: 1.4063rem;
                position: relative;
                &::after{
                    content: '';
                    position: absolute;
                    left: 0;
                    top: 0;
                    bottom: 0;
                    margin: auto;
                    height: 1.1875rem;
                    width: .0938rem;
                    background-color: #E5E5E5;
                }
            }
        }
    }
}


.mobile_menu_section{
    width: auto;
    display: flex;
    position: relative;
    height: 35px;
    margin-left: auto;
    @media (min-width: 991px) {
        display: none;
    }
    .instagram_icon{
        width: 35px;
        height: 35px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 10px;
    }
    .mobile_menu{
        width: 35px;
        height: 35px;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        background-color: transparent;
        border: none;
        span{
            width: 22px;
            height: 2px;
            background-color: #000;
            display: inline-block;
            position: relative;
            margin-top: 2px;
            margin-bottom: 2px;
        }
    }
}