.wrapper{
    width: 100%;
    position: relative;
    .inner{
        position: relative;
        padding-top: 20px;
        @media (min-width: 576px) {
            padding-top: 80px;
        }
    }
    
    .heading{
        display: flex;
        flex-direction: column;
        .title{
            margin-bottom: 10px;
            line-height: 1.5;
            font-size: 24px;
        }
        p{
            width: 100%;
            max-width: 400px;
        }
        ul{
            width: 100%;
            padding-top: 40px;
            padding: 0;
            margin: 0;
            margin-top: 50px;
            li{
                list-style: none;
                margin-bottom: 20px;
                a{
                    text-decoration: none;
                    color: #000;
                    font-weight: 500;
                    img{
                        width: 20px;
                        height: 20px;
                    }
                    .text{
                        padding-left: 20px;
                    }
                }
            }
        }
    }
}

.formGroup{
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-bottom: 30px;
    label{
        font-size: 16px;
        line-height: 1;
        margin-bottom: 8px;
        opacity: .5;
    }
    input{
        width: 100%;
        height: 45px;
        padding: 15px;
        border: 1px solid #dcdcdc;
        &:focus-visible{
            border: 1px solid #000;
            outline: none;
            box-shadow:  none;
        }
        &::placeholder{
            color: #000;
        }
    }
    textarea{
        width: 100%;
        padding: 15px;
        border: 1px solid #dcdcdc;
        &:focus-visible{
            border: 1px solid #000;
            outline: none;
            box-shadow:  none;
        }
        &::placeholder{
            color: #000;
        }
    }
    button{
        width: max-content;
        height: 45px;
        padding-left: 20px;
        padding-right: 20px;
        background-color: #000;
        color: #fff;
        text-decoration: none;
        border: none;
        margin-left: auto;
    }
}