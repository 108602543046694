.root{
    width: 100%;
    position: relative;
    background-color: #231209;
    padding: 25px 0px;
    .inner_wrapper{
        width: 100%;
       text-align: center;
        @media (min-width: 576px) {
            display: flex;
            justify-content: space-between;
            text-align-last: left;
        }
        p,
        a{
            color: #fff;
            text-decoration: none;
            font-weight: 400;
            font-size: 14px;
        }
        a{
            display: inline-block;
            margin-left: 4px;
            margin-right: 6px;
            &:last-child{
                margin-right: 0;
            }
        }
    }
}