.root{
    width: 100%;
    display: flex;
    flex-direction: column;
    position: relative;
    background-color: #592E18;
    margin-bottom: 62px;
    padding-bottom: 52px;
    padding-top: 52px;
    background: rgb(89,46,24);
    background: -moz-linear-gradient(0deg, rgba(89,46,24,1) 0%, rgba(103,53,27,1) 51%, rgba(89,46,24,1) 100%);
    background: -webkit-linear-gradient(0deg, rgba(89,46,24,1) 0%, rgba(103,53,27,1) 51%, rgba(89,46,24,1) 100%);
    background: linear-gradient(0deg, rgba(89,46,24,1) 0%, rgba(103,53,27,1) 51%, rgba(89,46,24,1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#592e18",endColorstr="#592e18",GradientType=1);
    @media (max-width: 576px) {
        margin-bottom: 40px;
    }
    &::after{
        width: 100%;
        top: -7px;
        height: 7px;
        content: "";
        position: absolute;
        content: "";
        left: 0;
        background-size: 32px;
        background-image: url("data:image/svg+xml,%3Csvg width='87' height='20' viewBox='0 0 87 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M64.7 8.9C58.7 3 50.9 0 43.1 0C35.3 0 27.5 3 21.6 8.9C15.6 14.9 7.8 17.8 0 17.8V19.8H86.2V17.8C78.4 17.9 70.6 14.9 64.7 8.9Z' fill='%23592E18'/%3E%3C/svg%3E%0A");
    }

    &::before{
        transform: scaleY(-1);
        width: 100%;
        bottom: -7px;
        height: 7px;
        content: "";
        position: absolute;
        content: "";
        left: 0;
        background-size: 32px;
        background-image: url("data:image/svg+xml,%3Csvg width='87' height='20' viewBox='0 0 87 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M64.7 8.9C58.7 3 50.9 0 43.1 0C35.3 0 27.5 3 21.6 8.9C15.6 14.9 7.8 17.8 0 17.8V19.8H86.2V17.8C78.4 17.9 70.6 14.9 64.7 8.9Z' fill='%23592E18'/%3E%3C/svg%3E%0A");
    }
    
}
.main_wrapper{
    color: #fff;
}

.heading{
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 24px;
    h4{
        margin-bottom: 0;
    }
}

.image_gallery_banner,
.image_gallery_1{
    width: 100%;
    position: relative;
    overflow: hidden;
    border-radius: 8px;
    border: 1px solid #76523e;
    &::after{
        position: relative;
        padding-bottom: 100%;
        // padding-bottom: calc(369 / 277 * 100%);
        content: '';
        display: block;
        width: 100%;
    }
    img{
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
}

.image_gallery_banner::after{
    padding-bottom: 47.8%;
}

.right_text{
    width: 100%;
    max-width: 395px;
    margin-top: 3px;
    > h4{
        line-height: 1.55;
        letter-spacing: 0.1px;
       
    }
}

.left_text{
    width: 100%;
    margin-top: 3px;
    position: relative;
    margin-left: -5px;
    > p{
        line-height: 1.4;
        margin-bottom: 8px;
        font-size: 16px;
        max-width: 400px;
        opacity: .9;
    }
}

.top_image_wrapper{
    width: 100%;
    position: relative;
    display: flex;
    flex-direction: column;
    padding-top: 32px;
}
.top_image{
    width: 100%;
    position: relative;
    z-index: 1;
    max-width: 590px;
    margin: 8px auto;
    &::after{
        content: '';
        position: relative;
        display: block;
        width: 100%;
        padding-bottom: calc(170 / 747 * 100%);
    }
    img{
        width: 100%;
        height: 100%;
        object-fit: contain;
        object-position: center;
        position: absolute;
    }
}