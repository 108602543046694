.auth_wrapper{
    width: 100%;
    min-height: 100vh;
    background-color: #fafafa;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;


    .auth_form_wrapper{
        width: 350px;
        position: absolute;
        height: auto;
        padding: 30px   20px;
        background-color: #fff;
        border: 1px solid #e7e7e7;


    }

    .form{
        width: 100%;
        display: flex;
        flex-direction: column;
        position: relative;

        .form_wrapper{
            width: 100%;
            position: relative;
            margin-bottom: 20px;
            input{
                width: 100%;
                margin: 0;
                padding: 10px 20px;
                border: 1px solid #e7e7e7;
                border-radius: 3px;
                color: #000;
                &::placeholder{
                    color: #000;
                }
            }
            
        }
        .btn_wrapper{
            width: 100%;
            position: relative;
            display: flex;
            button{
                border-radius: 4px !important;
                margin-left: auto;
                padding: 8px 20px !important;
                color: #fff;
            }
        }
    }

    .heading_wrapper{
        width: 100%;
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin-bottom: 40px;
        h2{
            font-size: 22px;
            line-height: 1.3;
            color: #000;
            margin-bottom: 10px;
        }
        p{
            font-size: 16px;
            line-height: 1.5;
            text-align: center;
            max-width: 200px;
            opacity: .8 ;
        }
    }
}