@import './bootsrap.scss';



.primary-font{
    font-family: 'Roboto', sans-serif;
}

.secondary-font{
    font-family: 'DM Serif Display', serif;
}

html *{
    margin-bottom: 0;
    margin-top: 0;
}

html{
    font-size: 16px;
    color: #000 ;
}
body{
    font-family: 'Roboto', sans-serif;
    font-size: 16px;
}

.ttl-1{
    font-size: 24px;
}
.ttl-2{
    font-size: 18px;
    @media (min-width: 991px) {
        font-size: 20px;
    }
}
.ttl-3{
    font-size: 24px;
}
.ttl-4{
    font-size: 20px;
}


.custom_btn{
    width: auto;
    position: relative;
    display: flex;
    color: #000;
    text-decoration: none;
    font-size: 16px;
    font-weight: 500;
    &.light{
        color: #fff;
    }
    &.primary{
        color: #fff;
        font-size: 14px;
        line-height: 1;
        text-decoration: none;
        font-weight: 400;
        border: 1px solid #fff;
        transition: .3s all ease;
        height: 30px;
        border-radius: .3125rem;
        &:hover{
            background-color: #fff;
            color: #000;

        }
    }
    &.secondary{
        color: #000;
        font-size: 14px;
        line-height: 1;
        text-decoration: none;
        font-weight: 400;
        border: 1px solid #fff;
        transition: .3s all ease;
        height: 30px;
        border-radius: .3125rem;
        background-color: #fff;
        &:hover{
            background-color: #000;
            color: #fff;

        }
    }
    span {
        &:first-child{
            & ~ span{
                padding-left: 8px;
                transform: scale(.8);
            }
        }
    }
    
}

p{
    line-height: 1.67;
}

main{
    min-height: 100vh;
    margin-top: 30px;
}

.check{
    width: 100%;
    display: flex;
    position: relative;
    align-items: center;
    height: 50px;
    margin-top: 32px;
    border:1px solid #dddddd;
    padding: 10px 15px;
    .check-inner{
        width: 100%;
        position: relative;
        padding-left: 30px;
    }

    .icon-check{
        width: 20px;
        height: 20px;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        margin: auto;
        background-color: #fafafa;
        border:1px solid #333;
        display: block;
        border-radius: 50%;
        overflow: hidden;
    }
    input{
        opacity: 0;
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        &:checked{
            & + .icon-check::after{
                width: 10px;
                height: 10px;
                background-color: #333;
                content: '';
                position: absolute;
                left: 50%;
                top: 50%;
                border-radius: 50%;
                transform: translate(-50%, -50%);
            }
        }
    }

    
}

.error-message{
    color: #f00;
    font-size: 14px;
    line-height: 1;
    margin-top: 10px;
    display: inline-block;
}