.root{
    width: 100%;
    position: relative;
    margin-top: 30px;
    margin-bottom: 100px;
    .root_inner{
        width: 100%;
        // max-width: 960px;
        display: flex;
        flex-wrap: wrap;
        .image_wrapper{
            width: 100%;
            display: flex;
            flex-direction: column;
            position: relative;
            @media (min-width: 991px) {
                width: 45%;
            }
        }
        .detail_wrapper{
            width: 100%;
            display: flex;
            flex-direction: column;
            position: relative;
            margin-top: 10px;
            padding-left: 0px;
            @media (min-width: 991px) {
                width: 55%;
                padding-left: 80px;
                margin-top: 30px;
            }
        }
    }

    .heading{
        width: 100%;
        position: relative;

        .title{
            font-size: 40px;
            line-height: 1.6;
            color: #000;
            text-decoration: none;
            font-weight: 400;
            font-family: "DM Serif Display", serif;
        }

    }

    .pricing{
        width: 100%;
        position: relative;
        margin-bottom: 20px;
        h2{
            font-size: 35px;
            line-height: 1.6;
            color: #000;
            text-decoration: none;
            font-weight: 600;
            span{
                font-size: 14px;
                font-weight: 300;
                margin-left: 7px;
            }
        }
    }

    .size_and_known{
        width: 100%;
        position: relative;
        margin-bottom: 20px;
        ul{
            padding: 0;
            margin: 0;
            text-decoration: none;
            display: flex;
            flex-wrap: wrap;
            li{
                list-style: none;
                width: 50%;
                display: flex;
                flex-direction: column;
            }
        }
    }
    .colors{
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        position: relative;
        margin-bottom: 20px;
        ul{
            padding: 0;
            margin: 0;
            display: flex;
            flex-wrap: wrap;
            width: 100%;
            li{
                list-style: none;
                text-decoration: none;
                margin-right: 20px;
                margin-bottom: 10px;
                display: flex;
                align-items: center;
                .color{
                    width: 20px;
                    height: 20px;
                    border-radius: 50%;
                    overflow: hidden;
                    display: flex;
                    margin-right: 10px;
                    background-color: rgb(177, 177, 177);
                    border: 1px solid #C3C3C3;
                    & + span{
                        font-size: 18px;
                        line-height: 1.6;
                        color: #000;
                        text-decoration: none;
                        font-weight: 500;
                    }
                }
            }
        }
    }
    .list_title{
        color: #686868;
        font-weight: 400;
        margin-bottom: 10px;
        width: 100px;
        font-size: 15px;
        margin-bottom: 8px;
        font-family: "DM Serif Display", serif;
    }
    .sub_title{
        font-size: 20px;
        line-height: 1.6;
        color: #000;
        text-decoration: none;
        font-weight: 500;
    }
    .action_btn{
        display: flex;
        flex-wrap: wrap;
        position: relative;
        justify-content: space-between;
        width: 100%;
        max-width: 400px;
        a{
            width: calc(50% - 7px);
            height: 50px;
            border: 1px solid #000;
            text-decoration: none;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 18px;
        }
    }
}

.description{
    width: 100%;
    position: relative;
    margin-top: 40px;
    
    .sub_title{
        font-size: 16px;
        line-height: 1.4;
        font-weight: 400;
    }
}

.call{
    color: #000;
    font-weight: 500;
    transition: .3s all ease-in-out;
    &:hover{
        background-color: #000;
        color: #fff;

    }
}
.whatsapp{
    background-color: #000;
    color: #fff;
    transition: .3s all ease-in-out;
    &:hover{
        background-color: #25D366;
        border: 1px solid #25D366 !important;
        color: #fff;
    }
}