.root{
  width: 100%;
  position: relative;
}

.popup{
  position: absolute;
  left: 0;
  top: 100%;
  button{
    width: 20px;
    height: 20px;
    position: absolute;
    top: -24px;
    right: 0px;
    background-color: #fff;
    box-shadow: rgb(0 0 0 / 15%) 0px 0px 0px 1px;
    border: none;
    display: flex;
    overflow: hidden;
    padding: 0px;
    line-height: 6.6;
    justify-content: center;
    align-items: center;
    border-radius: 4px !important;
    &::after{
      width: 10px;
      height: 1px;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%) rotate(45deg);
      background-color: #000;
      content: '';
    }
    &::before{
      height: 10px;
      width: 1px;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%) rotate(45deg);
      background-color: #000;
      content: '';
    }
  }
}

.color_wrapper{
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  position: relative;
  gap: 10px;
  > div{
    width: 30%;
    height: 38px;
    position: relative;
    border: 1px solid #e4e2e2;
    &:hover{
      .removeColor{
        opacity: 1;
      }
    }
  }
}
.add_color{
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: 1px solid #333;
  font-size: 14px;
  cursor: pointer;
  background-color: transparent;
  height: 100%;
}

.removeColor{
  width: 20px;
  height: 20px;
  border-radius: 50% !important;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 50%;
  transform: translateY(-50%) rotate(45deg);
  right: 8px;
  cursor: pointer;
  opacity: 0;
  z-index: 3;
  &::after{
    width: 10px;
    height: 1px;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    background-color: #000;
    content: '';
  }
  &::before{
    height: 10px;
    width: 1px;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    background-color: #000;
    content: '';
  }
}



.color_palette{
  position: relative;
  input{
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    position: relative;
    background-color: transparent;
    padding: 5px 15px;
    border: none;
    mix-blend-mode: difference;
    z-index: 1;
    color: #fff ;
    &::placeholder{
      color: #fff;
    }
  }
  .color{
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
  }
}