.modal{
    --bs-modal-width: 1000px;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    right: 0;
    opacity: 1;
    padding: 15px;
    h3{
        font-size: 20px;
        line-height: 1.3;
        margin-bottom: 30px;
    }
    .close{
        width: 30px;
        height: 30px;
        position: absolute;
        right: 15px;
        top: 15px;
        background-color: transparent;
        display: flex;
        align-items: center;
        justify-content: center;
        border: none;
        transform: rotate(45deg);
        opacity: .4;
        &::after{
            position: absolute;
            content: '';
            width: 22px;
            height: 2px;
            background-color: #000;
        }
        &::before{
            position: absolute;
            content: '';
            width: 22px;
            height: 2px;
            background-color: #000;
            transform: rotate(90deg);
        }
    }
    .product_wrapper{
        width: 100%;
        position: relative;
        .checkbox{
            position: absolute;
            width: 30px;
            height: 30px;
            border-radius: 50%;
            overflow: hidden;
            background-color: #fff;
            overflow: hidden;
            z-index: 999;
            left: 10px;
            top: 10px;
            transition: .3s all ease-in-out;
            display: flex;
            align-items: center;
            justify-content: center;
            &:hover{
                transform: scale(1.1);
            }
            input{
                opacity: 0;
                width: 100%;
                height: 100%;
                position: absolute;
                left: 0;
                top: 0;
                cursor: pointer;
                & + span{
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    font-size: 14px;
                    width: 20px;
                    height: 20px;
                    border-radius: 50%;
                    background-color: #eaeaea;
                }

            }
        }
    }
}