.root {
  width: 100%;
  position: relative;
  overflow: hidden;
  border-radius: 8px;
  background-color: #000000;
  display: flex;
  flex-direction: column;
  .image_wrapper {
    width: 100%;
    position: relative;
    aspect-ratio: 277/369;
    overflow: hidden;
    // &::after{
    //     background: rgb(0,0,0);
    //     background: -moz-linear-gradient(180deg, rgba(0,0,0,0) 0%, rgba(0,0,0,1) 100%);
    //     background: -webkit-linear-gradient(180deg, rgba(0,0,0,0) 0%, rgba(0,0,0,1) 100%);
    //     background: linear-gradient(180deg, rgba(0,0,0,0) 0%, rgba(0,0,0,1) 100%);
    //     filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#000000",endColorstr="#000000",GradientType=1);
    //     position: absolute;
    //     height: 40%;
    //     width: 100%;
    //     left: 0;
    //     bottom: 0;
    //     content: '';
    //     opacity: .5;
    // }

    img {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center;
      transition: 0.3s all ease;
    }
  }
  &:hover {
    .image_wrapper img {
      transform: rotate(2deg) scale(1.05);
    }
    .text_wrapper .name {
      transform: translateY(-5px);
    }
  }
  .text_wrapper {
    width: 100%;
    position: absolute;
    left: 0;
    bottom: 0;
    height: auto;
    z-index: 99;
    color: #fff;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    padding: 60px 15px 20px ;
    background: rgb(89,46,24);
background: -moz-linear-gradient(0deg, rgba(89,46,24,1) 0%, rgba(89,46,24,0) 82%);
background: -webkit-linear-gradient(0deg, rgba(89,46,24,1) 0%, rgba(89,46,24,0) 82%);
background: linear-gradient(0deg, rgba(89,46,24,1) 0%, rgba(89,46,24,0) 82%);
filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#592e18",endColorstr="#592e18",GradientType=1);
    @media (max-width: 576px) {
        padding: 16px 8px;
    }
    .name {
      width: 100%;
      position: relative;
      transition: 0.3s all ease;
      h4{
        font-size: 18px !important;
        @media (max-width: 576px) {
            font-size: 16px !important;
        }
      }
    }
    .detail_wrapper {
      display: flex;
      width: 100%;
      position: relative;
    //   justify-content: space-between;
      margin-top: 11px;
      :global(.ttl-2){
        @media (max-width: 576px) {
            font-size: 14px !important;
        }
      }
    }
    .clr_ft {
      width: auto;
      display: flex;
      align-items: center;
      h6 {
        margin-right: 24px;
        padding-right: 24px;
        position: relative;
        @media (max-width: 576px) {
            margin-right: 16px;
            padding-right: 16px;
        }
        &::after {
          width: 1px;
          height: 15px;
          position: absolute;
          bottom: 0;
          top: 0;
          right: 0;
          margin: auto;
          content: "";
          background-color: #fff;
        }
      }
    }
    .clr {
      padding: 0;
      margin: 0;
      display: flex;
      position: relative;
      .clr_icon {
        width: 14px;
        height: 14px;
        border-radius: 50%;
        background-color: #fff;
        overflow: hidden;
        list-style: none;
        display: none;
        &:first-child ~ * {
          margin-left: -5.5px;
        }
      }
    }
  }
}
