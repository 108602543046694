$primary : #000;
$secondary : #efebeb;

$theme-colors: (
  "primary":    $primary,
  "secondary":  $secondary
);

$colors: ();
$grays: ();




$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
);

$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1198px,
);


$gutters: (
  0: 0,
  1: 10px,
  2: 20px,
  3: 30px,
  4: 40px,
  5: 50px,
);