.empty{
    width: 100%;
    height: 70vh;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    h1{
        font-size: 100px;
        line-height: 1;
        margin-bottom: 20px;
    }
    p{
        font-size: 18px;
        line-height: 1.5;
        color: #333;
        margin-bottom: 60px;
    }
}