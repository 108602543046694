button{
    border-radius: 0px !important;
}
.form-select,
.form-control{
    border-radius: 0px !important;
    min-height: 50px;
    font-size: 16px;
    padding: 10px 15px;
    line-height: 1;
    display: flex;
    align-items: center;
    border: 1px solid #dddddd;
    color: #000;
    transition: .3s border ease-in-out;
    &::placeholder{
        color: #464646;
    }
    &:focus-visible{
        outline: none;
        box-shadow: none;
        border: 1px solid #878787;
    }
}
.form-control[type="file"]{
    padding: 16px 25px;
}
.errors{
    color: #f00;
    font-size: 14px;
    line-height: 1;
    margin-bottom: 0;
    margin-top: 15px;
}
.action-width{
    width: 150px;
}