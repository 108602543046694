.section {
    width: 100%;
    position: relative;
    padding: 20px 0px 0px;
    overflow: hidden;
    @media (min-width: 576px) {
        padding: 30px 0px 0px;
    }

    .title {
        width: 100%;
        position: relative;
        font-size: 24px;
        max-width: 374px;
        margin-left: auto;
        margin-right: auto;
        text-align: center;
        line-height: 1.4;
        @media (min-width: 576px) {
            font-size: 30px;
            max-width: 474px;
        }
        @media (min-width: 991px) {
            font-size: 25px;
        }
    }

    .btn_wrapper {
        width: auto;
        margin-left: auto;
        margin-right: auto;
        display: flex;
        justify-content: center;
        margin-top: 20px;
    }


    .banner_wrapper{
        width: 100%;
        position: relative;
        max-width: 814px;
        margin-left: auto;
        margin-right: auto;
        margin-top: 50px;
        .shape{
            position: absolute;
            right: -30px;
            top: -30px;
        }

        .banner_container{
            width: 100%;
            position: relative;
            display: flex;
            flex-direction: column;
            border-radius: 10px;
            overflow: hidden;
            &::after{
                content: '';
                position: relative;
                width: 100%;
                display: block;
                padding-bottom: calc(328/ 814 * 100%);
                // background-color: #e8e8e8;   
                @media (min-width: 576px) {
                    padding-bottom: calc(228/ 814 * 100%);
                }
            }
            img{
                position: absolute;
                left: 0;
                top: 0;
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }
    }
    .description_wrapper{
        width: 100%;
        position: relative;
        border: 1px solid rgba(71, 36, 19, 0.159);
        background-color: #fdfdfd;
        color: #000;
        padding: 20px;
        display: flex;
        flex-direction: column;
        border-radius: 10px;
        overflow: hidden;
        margin-top: 30px; 
        justify-content: space-between;
        align-items: center;
        @media (min-width: 576px) {
            margin-top: 80px; 
            flex-direction: row;
            padding: 30px;
        }
        h3{
            text-align: center;
            @media (min-width: 576px) {
                text-align-last: left;
            }
        }
        .text{
            width: 100%;
            max-width: 500px;
            position: relative;
            line-height: 1.5;
            text-align: center;
            margin-bottom: 20px;
           
            @media (min-width: 576px) {
                text-align: left;
                margin-bottom: 0;
            }
        }
        .link{
            position: relative;
            text-decoration: none;
            color: inherit;
            font-size: 18px;
            display: flex;
            white-space: pre;
            align-items: center;
            span{
                display: inline-block;
                margin-left: 10px;
            }
            svg{

                filter: invert(1);
            }
        }
    }
}

.descriptions{
    display: flex;
    flex-direction: column;
    position: relative;
    h3{
        font-size: 22px;
        margin-bottom: 5px;
    }
}