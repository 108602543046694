.wrapper{
    width: 100%;
    position: relative;
    padding-top: 20px;
    margin-bottom: 10px;
    padding-bottom: 10px;
    @media (min-width: 576px) {
        padding-top: 80px;
        margin-bottom: 40px;
        padding-bottom: 40px;
    }
    &::after{
        width: 100%;
        height: calc(100% - 300px);
        background-image: linear-gradient(
  0deg,
  hsl(0deg 0% 100%) 0%,
  hsl(20deg 18% 98%) 35%,
  hsl(20deg 18% 97%) 100%
);
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
    }
    &::before{
        width: 100%;
        top: 289px;
        height: 11px;
        content: "";
        position: absolute;
        content: "";
        left: 0;
        background-size: 50px;
        background-image: url("data:image/svg+xml,%3Csvg width='87' height='20' viewBox='0 0 87 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg clip-path='url(%23clip0_527_11)'%3E%3Cpath d='M64.7 8.9C58.7 3 50.9 0 43.1 0C35.3 0 27.5 3 21.6 8.9C15.6 14.9 7.8 17.8 0 17.8V19.8H86.2V17.8C78.4 17.9 70.6 14.9 64.7 8.9Z' fill='%23F8F6F5'/%3E%3C/g%3E%3Cdefs%3E%3CclipPath id='clip0_527_11'%3E%3Crect width='86.2' height='19.8' fill='white'/%3E%3C/clipPath%3E%3C/defs%3E%3C/svg%3E%0A");
    }
   
    .inner_wrapper{
        width: 100%;
        display: flex;
        flex-direction: column;
        position: relative;
        z-index: 1;
    }

    .heading{
        position: relative;
        margin-bottom: 20px;
        @media (min-width: 576px) {
            margin-bottom: 50px;
        }

        .title{
            width: 100%;
            max-width: 370px;
            text-align: center;
            margin: auto;
            margin-bottom: 0;
            line-height: 1.5;
            font-size: 24px;
            @media (min-width: 576px) {
                max-width: 500px;
            }
        }
    }
}

.images{
    width: 100%;
    position: relative;
    .image_inner{
        position: relative;

        .image{
            width: 100%;
            position: relative;
            border-radius: 0.625rem;
            overflow: hidden;
            &::after{
                position: relative;
                padding-bottom: 100%;
                // padding-bottom: calc(369 / 277 * 100%);
                content: '';
                display: block;
                width: 100%;
            }
            img{
                position: absolute;
                left: 0;
                top: 0;
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }
    }
}